import { dashId, types } from "../actions";

const initialState = {
  carreras: [],
  imagenes: [],
  imagenesid: [],
  productos: [],
  details:[],
  corredor:[],
  resultado:[],
  resultados:[],
  resultadoId:[],
  imagenesId:[],
  detailseventoid:[],
  imageneseventoid:[],
  imageneseventomapaid:[],
  dashid:""
};
export const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_INFO_CARRERA:
      return {
        ...state,
        imagenes: action.payload.map(e=>e.imagenes),
        carreras: action.payload,
      };
      case types.DASH_DEL_DETAILS:
                return{
                    ...state,
                    dashid: "",
                    detailseventoid:[],
                    details:[]             }
      case types.PRODUCTOS:
        return {
          ...state,
          productos: action.payload
        }
        case types.PRODUCTOID:
          return{
              ...state,
              details:action.payload,
              imagenesid:action.payload.imagenes.map(e=>e)
          }
          case types.DASH_ID:
            return{
                ...state,
                dashid:action.payload
            }
        case types.EVENTOID:
          return{
              ...state,
              detailseventoid:action.payload,
              imageneseventoid:action.payload.imagenes.map(e=>e),
              imageneseventomapaid:action.payload.imagenesmapas.map(e=>e)
          }
        case types.RESULTADOID:
          return{
              ...state,
              resultadoId:action.payload,
              imagenesId:action.payload.evento.imagenes.map(e=>e)
          }
        case types.GET_RESULTADOS:
          return{
              ...state,
              resultados:action.payload,
          }
          case types.GET_CORREDOR:
            return{
                ...state,
                corredor:action.payload
            }
          case types.SEND_EMAIL:
            return{
                ...state,
            }
          case types.GET_RES_DNI:
            return{
                ...state,
                resultado:action.payload
            }
          case types.POST_CORREDOR:
            return{
                ...state
                       }

    default:
      return { ...state };
  }
};
