import Dashboard from "../Componentes/Dashboard.jsx";
import Footer from "../Componentes/Footer.jsx";
import Navbar from "../Componentes/Navbar.jsx";
import { Outlet } from "react-router-dom";

const LayoutDash = () => {
  return (
    <>
      
      <Dashboard/>
      <Outlet />
    </>
  );
};

export default LayoutDash;
