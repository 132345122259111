import axios from 'axios';



const URL="/"

export const types={
GET_INFO_CARRERA: "GET_INFO_CARRERA",
PRODUCTOS: "PRODUCTOS",
PRODUCTOID: "PRODUCTOID",
POST_CORREDOR: "POST_CORREDOR",
GET_CORREDOR: "GET_CORREDOR",
GET_RES_DNI: "GET_RES_DNI",
GET_RESULTADOS: "GET_RESULTADOS",
RESULTADOID: "RESULTADOID",
EVENTOID: "EVENTOID",
POST_EVENTO:"POST_EVENTO",
DASH_ID: "DASH_ID",
SEND_EMAIL: "SEND_EMAIL",
DASH_DEL_DETAILS:"DASH_DEL_DETAILS"
}
export const getInfoCarrera=()=>{
    return async (dispatch)=>{
      try {
        let response= await axios.get(`${URL}infocarrera`)
        return dispatch({
          type: types.GET_INFO_CARRERA,
          payload:response.data
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
  export const getCorredor=(email)=>{
    return async (dispatch)=>{
      try {
        let response= await axios.get(`${URL}corredor?email=${email}`)
        return dispatch({
          type: types.GET_CORREDOR,
          payload:response.data
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
  export const consulta=(email,nombre,mensaje)=>{
    return async (dispatch)=>{
      try {
        let response= await axios.post(`${URL}email`, {email,nombre,mensaje})
        return dispatch({
          type: types.SEND_EMAIL,
          payload:response.data
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
  export const getResDni=(dni)=>{
    return async (dispatch)=>{
      try {
        let response= await axios.get(`${URL}resultado?dni=${dni}`)
        return dispatch({
          type: types.GET_RES_DNI,
          payload:response.data
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
  export const getResultados=(id)=>{
    return async (dispatch)=>{
      try {
        let response= await axios.get(`${URL}resultados/${id}`)
        return dispatch({
          type: types.GET_RESULTADOS,
          payload:response.data
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
  export const upgDniCorredor=(email,dni)=>{
    return async (dispatch)=>{
      try {
        let response= await axios.put(`${URL}upgCorredor?email=${email}`,dni)
        return response
      } catch (error) {
        console.log(error)
      }
    }
  }

export const getProductos=()=>{
    return async (dispatch)=>{
      try {
        let response= await axios.get(`${URL}productos`)
        return dispatch({
          type: types.PRODUCTOS,
          payload:response.data
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
export const getProductoId=(id)=>{
    return async (dispatch)=>{
      try {
        let response= await axios.get(`${URL}productos/${id}`)
        return dispatch({
          type: types.PRODUCTOID,
          payload:response.data
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
  export const dashId=(payload)=>{
    console.log("payload", payload)
    return{
      type: types.DASH_ID,
      payload:payload
    }
  }
  export function DashDelDetail(){
    return  function (dispatch){
      return dispatch({
        type: types.DASH_DEL_DETAILS,
        // type: types.DASH_ID,
      });
    }
  }

export const getEventoId=(id)=>{
    return async (dispatch)=>{
      try {
        let response= await axios.get(`${URL}evento/${id}`)
        return dispatch({
          type: types.EVENTOID,
          payload:response.data
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
export const getResId=(id)=>{
    return async (dispatch)=>{
      try {
        let response= await axios.get(`${URL}resultado/${id}`)
        return dispatch({
          type: types.RESULTADOID,
          payload:response.data
        })
      } catch (error) {
        console.log(error)
      }
    }
  }

  export const createCorredor = (payload) => {
    return async function (dispatch) {
      try {
        var json = await axios.post(`${URL}cargacorredor`, payload);
        return {
          type:types.POST_EVENTO,
          payload:json.data
        }
      } catch (error) {
        console.log(error);
      }
    };

  }
  export const createEvento = (payload) => {
    return async function (dispatch) {
      try {
        var json = await axios.post(`${URL}cargaevento`, payload);
        return json
      } catch (error) {
        console.log(error);
      }
    };

  }
  export const createorUpdateProducto = (payload) => {
    return async function (dispatch) {
      try {
        var json = await axios.post(`${URL}cargaproducto`, payload);
        return json
      } catch (error) {
        console.log(error);
      }
    };

  }
  export const cargaResultados = (id, resultados) => {
    return async function (dispatch) {
      try {
        var json = await axios.post(`${URL}cargaresultados`, {id , resultados});
        return json
      } catch (error) {
        console.log(error);
      }
    };

  }
  export const deleteEvento = (id) => {
    return async function (dispatch) {
      try {
        var json = await axios.delete(`${URL}delete/${id}`);
        return json
      } catch (error) {
        console.log(error);
      }
    };

  }
  export const deleteProducto = (id) => {
    return async function (dispatch) {
      try {
        var json = await axios.delete(`${URL}deleteprod/${id}`);
        return json
      } catch (error) {
        console.log(error);
      }
    };

  }
