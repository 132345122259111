import React, { useEffect, useState } from 'react'
import DashPreviewEvento from './DashPreviewEvento'
import { useSelector } from 'react-redux'

const DashEvento = () => {
    const [props, setProps] = useState([])
    const llamada = useSelector(state => state.carreras)


    useEffect(() => {
        setProps([...llamada])
    },[llamada])


    console.log("dash evento llamada", llamada)

    return (
    <section className="grid md:grid-cols-2 gap-14"> 
    {props?.map((data, index) => (
        <DashPreviewEvento data={data} key={index}/>        
    ))}
    </section>
  )
}

export default DashEvento