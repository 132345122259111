import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DashDelDetail, getEventoId, getInfoCarrera } from "../redux/actions";
import PostEvento from "./PostEvento";
import DashEvento from "./DashEvento";


const ModificarEvento = () => {
  const dispatch = useDispatch();
  const id = useSelector((state) => state.dashid);
  const eventos = useSelector((state) => state.detailseventoid);
  const handleVolver = () => {
    dispatch(DashDelDetail())
  }

  useEffect(() => {
    dispatch(getInfoCarrera());
    dispatch(getEventoId(id));
    console.log("id", id)
  }, [dispatch, id]);

  //   onClick={() => handleShowID(e.id)}
  return (
    <div>
      {eventos.titulo ? (
        <div>
            <button onClick={handleVolver} class="rounded px-4 py-2 text-xs bg-orange-300 text-blue-100 hover:bg-orange-400 duration-300">Volver</button>
          <PostEvento
          eventos={eventos} />
        </div>
      ) : (
        <div >
          <DashEvento />
        </div>
      )}
    </div>
  );
};

export default ModificarEvento;
