import React from "react";
import logo from "../assets/logo.png";
import whatsapp from "../assets/whatsapp.png";
import instagram from "../assets/instagram.png";
import facebook from "../assets/facebook.png";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer class="text-gray-600 body-font">
      <div class=" w-full h-px max-w-6xl mx-auto my-12 bg-gray-400">
    </div>
      <div class="container px-5 mt-8 mb-8 mx-auto flex flex-row">
        <a href="/" className="flex items-center">
          <img src={logo} alt="logo" className="h-10 md:h-20" />
        </a>
        <div class="grid grid-cols-2 gap-4  w-full text-center">
          <li className="list-none">
            <Link to="">
              <a
                href=""
                className="text-sm md:text-lg font-semibold hover:text-gray-900 hover:font-bold "
              >
                Inicio
              </a>
            </Link>
          </li>
          <li className="list-none">
            <Link to="">
              <a
                href=""
                className="text-sm md:text-lg font-semibold hover:text-gray-900 hover:font-bold"
              >
                Eventos
              </a>
            </Link>
          </li>
          <li className="list-none">
            <Link to={"/Nosotros"}>
              <a
                href=""
                className="text-sm md:text-lg font-semibold hover:text-gray-900 hover:font-bold"
              >
                Nosotros
              </a>
            </Link>
          </li>
          <li className="list-none">
            <Link to="">
              <a
                href=""
                className="text-sm md:text-lg font-semibold hover:text-gray-900 hover:font-bold"
              >
                Productos
              </a>
            </Link>
          </li>
          <li className="list-none">
            <Link to="">
              <a
                href=""
                className="text-sm md:text-lg font-semibold hover:text-gray-900 hover:font-bold"
              >
                Certificado médico
              </a>
            </Link>
          </li>
        </div>
      </div>
      <div class="bg-gray-100 pt-4">
        <div class="container mx-auto pb-4 px-5 flex flex-wrap  md:flex-col flex-row">
          <p class="text-sm inline-flex items-center text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">
            © 2024 Abraham Molina
          <span class="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
            <a
              class="ml-5 mr-5"
              Target="_blank"
              href="https://api.whatsapp.com/send?phone=543834527609&text=%C2%A1Buenos%20dias%20Juan!%20Soy"
              >
              <img src={whatsapp} className="h-6 md:h-10" alt="logo" />
            </a>
            <a
              class="ml-5 mr-5"
              Target="_blank"
              href="https://www.facebook.com/CATArunnn?locale=es_LA"
              >
              <img src={facebook} className="h-6 md:h-10" alt="logo" />
            </a>
            <a
              class="ml-5 mr-5"
              Target="_blank"
              href="https://www.instagram.com/catarun_?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
              >
              <img src={instagram} className="h-6 md:h-10" alt="logo" />
            </a>
          </span>
              </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
