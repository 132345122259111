import React from "react";
import { Link } from "react-router-dom";

import { SiGooglemaps } from "react-icons/si";

const InfoCarrera = ({ props }) => {
  let date = new Date
  let fechacomp = date.toISOString().split("T")[0]
  return (
      <Link to={`/Eventos/${props.id}`}>
    <div className="relative overflow-hidden transition-transform duration-300 ease-in-out rounded-lg shadow-lg group hover:shadow-xl hover:-translate-y-2">
        <span className="sr-only">Ver carrera</span>
      <img
        src={props.imagenes}
        alt="image"
        width={550}
        height={310}
        className="object-fill w-full h-[22rem] group-hover:opacity-50 transition-opacity"
        />
      <div className="p-4 bg-background">
        <h3 className="text-xl font-bold">{props.titulo}</h3>
        <div className="flex items-center justify-center text-sm text-muted-foreground">
          <SiGooglemaps className="w-4 h-4" />
          <p className="text-sm text-muted-foreground">{props.ubicacion}</p>
        </div>
        { props.fecha < fechacomp ?
        (<a
          className="inline-flex h-6 items-center justify-center rounded-2xl bg-orange-200 px-4 py-2 text-sm font-normal shadow transition-colors hover:bg-red-400 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 ">
          Evento Finalizado
        </a>):(<a
          className="inline-flex h-6 items-center justify-center rounded-2xl bg-orange-200 px-4 py-2 text-sm font-normal shadow transition-colors hover:bg-orange-300 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 ">
          Más información
        </a>)
      }
      </div>
    </div>
          </Link>
  );
};

export default InfoCarrera;
