import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { LogoutButton } from "./Logout";
import { getCorredor, getResDni, upgDniCorredor } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import perfil from "../assets/perfil.png";
import Dash from "../assets/dashboard.png";
import Res from "../assets/resultados.png";
import Perfil from "./Perfil.jsx";
import Resultados from "./Resultados";
import Dashboard from "./Dashboard";
import { Link } from "react-router-dom";

export const Profile2 = () => {
  const dispatch = useDispatch();
  const { user, isAuthenticated, isLoading } = useAuth0();
  const corredor = useSelector((state) => state.corredor);
  const [show, setShow] = useState("");
  console.log("location",  window.location.origin)

  function changeViewPerfil(){
    setShow("Perfil")
  }
  function changeViewResultados(){
    setShow("Resultados")
  }
  function changeViewDashboard(){
    setShow("Dashboard")
  }
  useEffect(() => {
    if(isAuthenticated){
      dispatch(getCorredor(user.email));
    }
  }, [dispatch]);

  return (
    isAuthenticated ? (
      <div className="flex flex-row h-full ">
        <aside class="flex flex-col w-64 h-[700px] pl-8 py-8 overflow-hidden bg-white border-r rtl:border-r-0 rtl:border-l ">
          <a href="#">
            <p className="text-2xl">Hola {user.given_name}!</p>
            <img
              src={user.picture}
              alt="avatar"
              className="h-[150px] w-[150px] rounded-full border-2 border-verde-dark"
            />
          </a>

          <div class="flex flex-col justify-between flex-1 mt-6">
            <nav class="-mx-3 space-y-3 ">
              <button onClick={changeViewPerfil} class="flex items-center px-3 py-2 text-black transition-colors duration-300 transform rounded-lg hover:bg-gray-400 hover:text-white">
                <img src={perfil} alt="" className="h-[20px]" />
                <span class="mx-2 text-sm font-medium">Perfil</span>
              </button>
              <button onClick={changeViewResultados} class="flex items-center px-3 py-2 text-black transition-colors duration-300 transform rounded-lg hover:bg-gray-400 hover:text-white">
                <img src={Res} alt="" className="h-[20px]" />
                <span class="mx-2 text-sm font-medium">Resultados</span>
              </button>
              {corredor?.tipo === "Admin" ?
                <Link to={"/Dashboard/Eventos"}>
                <button class="flex items-center px-3 py-2 text-black transition-colors duration-300 transform rounded-lg hover:bg-gray-400 hover:text-white">
                <img src={Dash} alt="" className="h-[20px]" />
                <span class="mx-2 text-sm font-medium">Dashboard</span>
              </button> 
                </Link>
              : <></>
            }
            </nav>
            <LogoutButton />
          </div>
        </aside>
        {show === "Perfil" ? 
        <Perfil user={user} corredor={corredor}/> : 
        show === "Resultados" ? 
        <Resultados/>:
        <></>
        }
        
      </div>
    ):(<div class="flex items-center justify-center min-h-screen bg-cover bg-center bg-no-repeat">
      <div class="max-w-md mx-auto text-center bg-white bg-opacity-90 p-8 rounded-lg shadow-lg">
        <div class="text-9xl font-bold text-orange-600 mb-4">404</div>
        <h1 class="text-4xl font-bold text-gray-800 mb-6">Oops! Pagina No Encontrada</h1>
        <p class="text-lg text-gray-600 mb-8">La pagina que buscas no esta disponible si no iniciaste sesión</p>
        <a href="/"
          class="inline-block bg-orange-600 text-white font-semibold px-6 py-3 rounded-md hover:bg-indigo-700 transition-colors duration-300">
          Volver al inicio
        </a>
      </div>
    </div>)
  );
};
