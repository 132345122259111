import React from "react";
import video from "../assets/video.mp4";

const AboutUs = () => {
  return (
    <div className="w-full flex justify-center items-center flex-col">
      <section className="w-[80%] md:pt-10 md:pb-6 ">
        <div className="container">
          <div className="grid gap-6 lg:grid-cols-2 lg:gap-12">
            <div className="space-y-4 md:pl-32">
              <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
                Sobre nosotros
              </h1>
              <p className="max-w-[600px] md:text-xl">
                Cata Run Eventos Deportivos es un emprendimiento catamarqueño
                fundado en el año 2014 por un grupo de apasionados deportistas
                con más de 50 exitosos eventos realizados. Nos dedicamos a la
                organización de eventos deportivos especializados en running,
                trail running y pruebas combinadas.
              </p>
              <div className="space-y-4">
                <h2 className="text-2xl font-bold">Nuestra Misión</h2>
                <p className="text-muted-foreground">
                  Nuestro enfoque principal es fomentar el turismo deportivo y
                  apoyar las economías regionales de la provincia de Catamarca,
                  así como de provincias aledañas que busquen asesoramiento en
                  el diseño, organización, difusión y logística de eventos
                  deportivos exitosos.
                </p>
              </div>
              <div className="space-y-4">
                <h2 className="text-2xl font-bold">Experiencia y Pasión</h2>
                <p className="text-muted-foreground">
                  Nuestra experiencia y pasión por el deporte nos permiten
                  ofrecer soluciones integrales para la realización de eventos
                  de alto nivel, brindando un impacto positivo tanto en la
                  comunidad deportiva como en el desarrollo turístico y
                  económico de la región.
                </p>
              </div>
              <div className="space-y-4">
                <h2 className="text-2xl font-bold">
                  Compromiso con la Excelencia
                </h2>
                <p className="text-muted-foreground">
                  Estamos comprometido con la excelencia, la innovación y el
                  profesionalismo en cada evento que organizamos. Esperamos
                  poder colaborar con el crecimiento del turismo deportivo y el
                  fortalecimiento de las economías locales a través de la
                  celebración de eventos deportivos memorables y exitosos.
                </p>
              </div>
              <div className="space-y-4">
                <h2 className="text-2xl font-bold">
                  Más que Eventos, Experiencias
                </h2>
                <p className="text-muted-foreground">
                  Cata Run Eventos Deportivos no solo organiza eventos; crea
                  experiencias visuales y sensoriales que van más allá de la
                  competición pura y dura. Su compromiso con el diseño de
                  remeras técnicas excepcionales y la captura de momentos únicos
                  en paisajes catamarqueños convierten cada evento en una
                  celebración de la pasión por el deporte y el amor por la
                  tierra que lo acoge.
                </p>
              </div>
            </div>
            <video width={480} className="md:ml-[125px] mt-12" autoPlay controls>
              <source src={video} type="video/mp4" />
            </video>
          </div>
        </div>
      </section>
      <section className="w-[80%] flex flex-col items-center justify-center bg-muted">
        <div className="container ">
          <div className="grid gap-4 md:grid-cols-2 justify-center pt-8 md:pt-0">
            <div className="space-y-4 md:pl-32">
              <h2 className="text-2xl font-bold">Nuestro Distintivo:</h2>
              <ul className="gap-4">
                <li className="flex items-start gap-4">
                  <div>
                    <h3 className="text-lg font-medium"> Remeras Técnicas</h3>
                    <p className="text-muted-foreground">
                      Cata Run Eventos Deportivos destaca entre las empresas del
                      rubro gracias a una característica distintiva que resalta
                      el compromiso con la excelencia y la atención al detalle:
                      el diseño de remeras técnicas personalizadas para cada
                      evento. Estas prendas no solo aportan color y vitalidad a
                      cada competición, sino que también se han convertido en el
                      distintivo de los deportistas que buscan calidad, diseño y
                      comodidad en cada una de sus sesiones de entrenamiento
                      personal.
                    </p>
                  </div>
                </li>
                <li className="flex items-start gap-4">
                  <div>
                    <h3 className="text-lg font-medium">Calidad y Diseño</h3>
                    <p className="text-muted-foreground">
                      Las remeras técnicas de Cata Run no son simplemente
                      prendas deportivas; son una declaración de compromiso con
                      el rendimiento y la pasión por el deporte. Fabricadas con
                      los más altos estándares de calidad, estas prendas ofrecen
                      a los atletas una experiencia única, combinando diseño
                      innovador y materiales de vanguardia que se adaptan a las
                      exigencias de cualquier disciplina.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="space-y-4 md:pl-32">
              <h2 className="text-2xl font-bold">
                Celebrando la Belleza de Catamarca
              </h2>
              <p className="text-muted-foreground ">
                En definitiva, la empresa va más allá de la simple organización
                de eventos deportivos al incorporar la majestuosidad de los
                paisajes catamarqueños en cada fotografía de alta calidad. Esta
                práctica no solo busca destacar la belleza natural de la
                provincia, sino que también refleja uno de los objetivos
                fundamentales de Cata Run: "Conoce tu provincia haciendo lo que
                te apasiona junto a tu entorno de amigos y familia". Las
                imágenes capturan no solo la esencia de la competición, sino
                también la conexión única entre los deportistas, su entorno y la
                cultura local.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
