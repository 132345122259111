import React from 'react'
import { dashId, deleteProducto } from '../redux/actions';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

const DashPreviewProducto = ({producto}) => {
    const dispatch = useDispatch();
  let id = producto.id
  const handleId = () => {
    dispatch(dashId(producto.id));
  };
  console.log("id",id)
  console.log("producto img",producto.imagenes)

  const borrar = () => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Sí, elimínalo!"
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteProducto(id))
        Swal.fire({
          title: "Eliminado!",
          text: "Has eliminado el producto!",
          icon: "success"
        });
      }
    });
  }
  if (producto) {
  return (
    <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
        <a class="block relative h-48 rounded overflow-hidden">
          <img
            alt="ecommerce"
            class="object-contain object-center w-full h-full block"
            src={producto?.imagenes[0]}
          />
        </a>
        <div class="mt-4 text-center">
          <h2 class="text-gray-900 title-font text-lg font-medium">
            {producto.nombre}
          </h2>
          <p class="mt-1">${producto.precio}</p>
        </div>
        <div class="flex flex-row gap-2">
          <button 
          class="bg-blue-700 text-white w-full p-2 flex flex-row justify-center gap-2 items-center rounded-sm hover:bg-gray-900 duration-100 ease-in-out"
          id={producto.id}
          onClick={() => handleId()}
          >
          Modificar
        </button>
        <button 
        class="bg-red-500 text-white w-full p-2 flex flex-row justify-center gap-2 items-center rounded-sm hover:bg-black duration-100 ease-in-out"
        onClick={() => borrar()}
        >
          Eliminar
        </button>
        
      </div> 
    </div>
  )
}
}

export default DashPreviewProducto