import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { consulta, getProductoId } from "../redux/actions";
import { useParams } from "react-router-dom";

const DetailProducto = () => {
  const dispatch = useDispatch();
  const detalle = useSelector((state) => state.details);
  const imagenes = useSelector((state) => state.imagenesid);
  const [ordenimg, setOrdenimg] = useState("");

  let [email, setEmail] = useState("")
  let [nombre, setNombre] = useState("")
  let [mensaje, setMensaje] = useState(``)

  const params = useParams();
  const id = params.id;

  console.log("detalle", detalle);
  function handleImage(e) {
    setOrdenimg(e.target.src);
  }

  function handleEmail (e) {
    setEmail(email = e.target.value)
  }
  function handleNombre (e) {
    setNombre(e.target.value)
  }
  function handleMensaje (e) {
    setMensaje("Consulta de " + detalle.nombre + " " + e.target.value)
  }
  function EnviarConsulta () {
    dispatch(consulta(email,nombre,mensaje))
  }


  console.log("nombre",nombre)
  console.log("email",email)
  console.log("mensaje",mensaje)

  useEffect(() => {
    dispatch(getProductoId(id));
  }, [dispatch]);

  return (
    <section class="text-gray-600 body-font overflow-hidden">
      <div class="container px-5 py-24 mx-auto">
        <div class="lg:w-4/5 mx-auto flex flex-wrap">
          <div className="flex flex-col items-center  dark:bg-main-dark">
            <div className=" dark:bg-main-dark">
              <img
                src={ordenimg === "" ? imagenes[0] : ordenimg}
                alt="imagen del product"
                width="550px"
                height="550px"
                className="dark:bg-main-dark"
              />
            </div>
            <div className="flex flex-row items-center gap-[20px]">
              {imagenes.map((d) => {
                return (
                  <button
                    onClick={(e) => handleImage(e)}
                    value={d}
                    className="hover:shadow-xl transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-100 mt-[10px]"
                  >
                    <img
                      src={d}
                      alt="imagen del product"
                      width="120px"
                      height="120px"
                    />
                  </button>
                );
              })}
            </div>
          </div>
          <div class="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
            <h1 class="text-gray-900 text-3xl title-font font-medium mb-1">
              {detalle.nombre}
            </h1>
            <div className="pt-7 lg:pt-14 2xl:pt-14">
                <div className="mb-3 xl:mb-2">
                  <input
                    type="text"
                    id="nombre"
                    name="nombre"
                    className="p-1 w-full"
                    onChange={(e) => handleNombre(e)}
                    placeholder="Nombre y Apellido"
                  />
                </div>
                <div className="mb-3 xl:mb-2">
                  <input
                    type="email"
                    id="email"
                    onChange={(e) => handleEmail(e)}
                    name="email"
                    className="p-1 w-full"
                    placeholder="Email"
                  />
                </div>
                <div className="mb-2 xl:mb-2">
                  <textarea
                    id="mensaje"
                    name="mensaje"
                    onChange={(e) => handleMensaje(e)}
                    className="p-1 w-full resize-none"
                  ></textarea>
                </div>
                <button
                  onClick={EnviarConsulta}
                  className="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded"
                >
                  Enviar consulta
                </button>
            </div>
            <div class="flex">
              <span class="title-font font-medium text-2xl text-gray-900">
                ${detalle.precio}
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DetailProducto;
