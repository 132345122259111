import "./ticket.css";
import React, { useEffect } from "react";
import logo from "../assets/logo.png";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getResId } from "../redux/actions";
import html2canvas from "html2canvas";
import Canvas2Image from "./canvas2image";
import descargar from "../assets/Descargar.png";

const Ticket1 = ({id}) => {
  // const params = useParams();
  const dispatch = useDispatch();
  const resultado = useSelector((state) => state.resultadoId);
  const imagenes = useSelector((state) => state.imagenesId);
  // const id = params.id;

  useEffect(() => {
    dispatch(getResId(id));
  }, [dispatch]);

  function f() {
    html2canvas(document.querySelector("#capture"), {
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
      windowWidth: document.documentElement.offsetWidth,
      windowHeight: document.documentElement.offsetHeight,
      allowTaint: true,
      useCORS: true,
    }).then((canvas) => {
      return Canvas2Image.saveAsJPEG(canvas);
    });
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div className="relative bg-white rounded-lg shadow-lg">       
    <div class="containert bg-black bg-opacity-50">
      <div class="ticket airline" id="capture">
        <div class="top">
          <img
            src={imagenes[0]}
            className="w-[350px] h-[300px]"
            alt="imgcarrera"
            />
        </div>
        <div class="bottom">
          <div class="column">
            <h1 className="text-[13px] font-bold">
              {resultado?.evento?.titulo}
            </h1>
            <div class="row row-2">
              <p>
                <span>Nombre y Apellido</span>
                {resultado.nombreApellido}
              </p>
              <p class="row--right">
                <span>Dorsal</span>
                {resultado.dorsal}
              </p>
            </div>
            <div class="row row-2cat">
              <p class="row--center">
                <span>Categoria</span>
                {resultado.categoria}
              </p>
            </div>
            <div class="row row-3">
              <p>
                <span>Pos. Gral.</span>
                {resultado.posicionGeneral}
              </p>
              <p class="row--center">
                <span>Pos. Sexo</span>
                {resultado.posicionSexo}
              </p>
              <p class="row--right">
                <span>Pos. Cat</span>
                {resultado.posicionCategoria}
              </p>
              <p class="row--right">
                <span>Tiempo</span>
                {resultado.total}
              </p>
            </div>
          </div>
        </div>
      </div>
      <button
        onClick={f}
        class="z-20 p-8 flex flex-col shrink-0 grow-0 justify-around 
                  fixed bottom-0 right-0 rounded-lg"
                  >
        <div class="rounded-full">
          <img src={descargar} alt="descarga" className="h-16 w-16" />
        </div>
      </button>
      </div>
    </div>
    </div>
  );
};

export default Ticket1;
