import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import logo from "../assets/logo.png";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton } from "./Login";
import { Profile } from "./Profile";
import { useSelector, useDispatch } from "react-redux";
import { createCorredor, getCorredor } from "../redux/actions";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const { isAuthenticated, user } = useAuth0();
  const dashuser = useSelector((state) => state.dashUser);
  const email = user?.email;
  const dispatch = useDispatch();

  const closeMenu = () => {
    setIsOpen(!isOpen);
  };

  var styles = {
    bmMenuWrap: {
      
    },
    bmMenu: {
      background: "rgba(254, 215, 170, 0.9)",
    },
    bmItem: {
      color: "#ffffff",
      padding: "20px",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0)",
    },
  };

  const handleShow = () => {
    setIsShow(!isShow);
  };

  useEffect(() => {
    if (isAuthenticated) {
      let bodydepost = {
        nombre: user.given_name,
        apellido: user.family_name,
        email: user.email,
        picture: user.picture,
      };
      dispatch(createCorredor(bodydepost));
    }
  }, [isAuthenticated]);

  useEffect(() => {
    dispatch(getCorredor(email));
  }, [email]);

  return (
    <header class="text-gray-600 body-font">
      <div class="container mx-auto flex p-5 items-center">
        <a href="/">
          <img src={logo} alt="logo" />
        </a>
        <nav class="hidden md:flex md:mr-auto md:ml-auto flex-wrap items-center text-base justify-center">
          <Link to="">
            <a href="" className=" mr-10 hover:text-gray-900 hover:font-bold">
              Inicio
            </a>
          </Link>
          <Link to={"/Eventos"}>
            <a href="" className=" mr-10 hover:text-gray-900 hover:font-bold">
              Eventos
            </a>
          </Link>
          <Link to={"/Nosotros"}>
            <a href="" className=" mr-10 hover:text-gray-900 hover:font-bold">
              Nosotros
            </a>
          </Link>
          {/* <a
            onClick={handleShow}
            class=" mr-10 hover:text-gray-900 hover:font-bold"
          >
            Servicios
          </a>
          <div
            class={isShow ? "absolute top-16 z-50 mt-2 origin-top " : "hidden"}
          >
            <div class=" bg-white rounded-md shadow-lg ">
              <div class="grid grid-cols-1 md:grid-cols-1 gap-2">
                <a
                  class=" flex  items-start rounded-lg bg-transparent p-2 hover:font-bold hover:bg-slate-500"
                  href="#"
                  onClick={handleShow}
                >
                  Cata CRONO
                </a>
                <a
                  class="flex row items-start rounded-lg bg-transparent p-2 hover:font-bold hover:bg-slate-500"
                  href="#"
                  onClick={handleShow}
                >
                  Org. Eventos
                </a>
              </div>
            </div>
          </div> */}
          <Link to={"/Productos"}>
            <a href="" className=" mr-10 hover:text-gray-900 hover:font-bold">
              Productos
            </a>
          </Link>
          <Link
            to={
              "https://drive.google.com/file/d/1EWO1zRUdChdGIRcpVIf7mYdrqP7xFWjB/view?usp=drive_link"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <a className=" mr-10 hover:text-gray-900 hover:font-bold">
            Certificado médico
            </a>
          </Link>
        </nav>
        {isAuthenticated ? (
          <>
            <Profile />
          </>
        ) : (
          <LoginButton />
        )}
        <div className="md:hidden py-1 px-3 inline-flex items-center">
          <button
            className="text-2xl text-orange-200 p-2 "
            onClick={() => setIsOpen(!isOpen)}
          >
            ☰
          </button>
        </div>
        <Menu
          styles={styles}
          right
          width={"30%"}
          isOpen={isOpen}
          onStateChange={({ isOpen }) => setIsOpen(isOpen)}
          customCrossIcon={false}
          customBurgerIcon={false}
        >
          <Link onClick={closeMenu} to="">
            <a
              href=""
              className="mr-10 text-black hover:text-gray-900 hover:font-bold"
            >
              Inicio
            </a>
          </Link>
          <Link onClick={closeMenu} to={"/Eventos"}>
            <a
              href=""
              className="mr-10 text-black hover:text-gray-900 hover:font-bold"
            >
              Eventos
            </a>
          </Link>
          <Link to={"/Nosotros"}>
            <a
              href=""
              className=" mr-10 text-black hover:text-gray-900 hover:font-bold"
            >
              Nosotros
            </a>
          </Link>
          <Link onClick={closeMenu} to={"/Productos"}>
            <a
              href=""
              className="mr-10 text-black hover:text-gray-900 hover:font-bold"
            >
              Productos
            </a>
          </Link>
          <Link
            onClick={closeMenu}
            to={
              "https://drive.google.com/file/d/1EWO1zRUdChdGIRcpVIf7mYdrqP7xFWjB/view?usp=drive_link"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <a className="mr-10 no-underline text-black hover:text-gray-900 hover:font-bold">
            Certificado médico
            </a>
          </Link>
        </Menu>
      </div>
    </header>
  );
};

export default Navbar;
