import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCorredor, getResDni } from "../redux/actions";
import Lista from "./Lista";
import primero from "../assets/1ro.png";
import coronamasc from "../assets/1ro-gralMasc.png";
import coronafem from "../assets/1ro-gralFem.png";
import segundo from "../assets/2do.png";
import tercero from "../assets/3ro.png";
import { Link } from "react-router-dom";
import Ticket1 from "./Ticket1";

const Resultados = () => {
  const dispatch = useDispatch();
  const { user, isAuthenticated, isLoading } = useAuth0();
  const resultados = useSelector((state) => state.resultado);
  const corredor = useSelector((state) => state.corredor);
  const [showModal, setShowModal] = useState(false);

    console.log("resultados",resultados)

  useEffect(() => {
    dispatch(getCorredor(user.email));
  }, []);
  useEffect(() => {
    dispatch(getResDni(corredor.dni));
  }, [corredor]);

  return (
    <section class="container px-4 py-8 mx-auto">
      <h2 class="text-lg font-medium text-black">Resultados</h2>
      {showModal ? (
        <button onClick={() => setShowModal(!showModal  )} className="z-100 top-[50%] right-[50%] text-black font-extrabold text-7xl ">
        <Ticket1 id={resultados[0]?.id} />        
          </button>
      ): (
        <div class="flex flex-col mt-6">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div class="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead class="bg-gray-50 dark:bg-gray-800">
                  <tr>
                    <th
                      scope="col"
                      class="py-3.5 px-2 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                    >
                      <span>Nombre</span>
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                    >
                      Tiempo
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                    >
                      Dorsal
                    </th>

                    <th
                      scope="col"
                      class="px-2 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                    >
                      Pos. General
                    </th>

                    <th
                      scope="col"
                      class="px-2 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                    >
                      Pos. Sexo
                    </th>

                    <th
                      scope="col"
                      class="px-2 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                    >
                      Pos. Categoria
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                    >
                      Categoria
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                    >
                      Localidad
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                    >
                      Carrera
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                  {resultados?.length > 1 ? (
                    resultados.map((e, i) => <Lista e={e} i={i} />)
                  ) : (
                    <tr className="text-white">
                      <td class="px-2 py-4 text-sm font-medium whitespace-nowrap">
                        <button
                          onClick={() => setShowModal(!showModal  )}
                        >
                          {/* to={`/ticket/${resultados[0]?.id}`} */}
                          <div>
                            <h2 class="font-medium text-white">
                              {resultados[0]?.posicionGeneral === "1" ? (
                                resultados[0]?.categoria.includes(
                                  "Masculino"
                                ) ? (
                                  <div className="inline px-2 py-1">
                                    {resultados[0]?.nombreApellido}
                                    <img
                                      src={coronamasc}
                                      className="h-[20px] w-[20px] inline mr-3"
                                      alt="1ro"
                                    />
                                  </div>
                                ) : resultados[0]?.categoria.includes(
                                    "Femenino"
                                  ) ? (
                                  <div className="inline px-2 py-1">
                                    {resultados[0]?.nombreApellido}
                                    <img
                                      src={coronafem}
                                      className="h-[20px] w-[20px] inline mr-3"
                                      alt="1ro"
                                      />
                                  </div>
                                ) : (
                                  <></>
                                )
                              ) : (
                                <>{resultados[0]?.nombreApellido}</>
                              )}
                            </h2>
                          </div>
                        </button>
                      </td>
                      <td class="px-2 py-4 text-sm font-medium whitespace-nowrap">
                        <div class="inline px-2 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 dark:bg-gray-800">
                          {resultados[0]?.total}
                        </div>
                      </td>
                      <td class="px-2 py-4 text-sm whitespace-nowrap">
                        {resultados[0]?.dorsal}
                      </td>
                      <td class="px-2 py-4 text-sm whitespace-nowrap">
                        {resultados[0]?.posicionGeneral === "1" ? (
                          <div className="inline px-3 py-1">
                            {" "}
                            <img
                              src={primero}
                              className="h-[20px] w-[20px] inline"
                              alt="1ro"
                            />
                            {resultados[0]?.posicionGeneral}{" "}
                          </div>
                        ) : resultados[0]?.posicionGeneral === "2" ? (
                          <div className="inline px-3 py-1">
                            {" "}
                            <img
                              src={segundo}
                              className="h-[20px] w-[20px] inline"
                              alt="2do"
                              />
                            {resultados[0]?.posicionGeneral}{" "}
                          </div>
                        ) : resultados[0]?.posicionGeneral === "3" ? (
                          <div className="inline px-3 py-1">
                            {" "}
                            <img
                              src={tercero}
                              className="h-[20px] w-[20px] inline"
                              alt="3ro"
                            />
                            {resultados[0]?.posicionGeneral}{" "}
                          </div>
                        ) : (
                          <div className="inline px-3 py-1">
                            {resultados[0]?.posicionGeneral}{" "}
                          </div>
                        )}
                      </td>
                      <td class="px-2 py-4 text-sm whitespace-nowrap">
                        {resultados[0]?.posicionSexo === "1" ? (
                          <div className="inline px-3 py-1">
                            {" "}
                            <img
                              src={primero}
                              className="h-[20px] w-[20px] inline"
                              alt="1ro"
                              />
                            {resultados[0]?.posicionSexo}{" "}
                          </div>
                        ) : resultados[0]?.posicionSexo === "2" ? (
                          <div className="inline px-3 py-1">
                            {" "}
                            <img
                              src={segundo}
                              className="h-[20px] w-[20px] inline"
                              alt="2do"
                              />
                            {resultados[0]?.posicionSexo}{" "}
                          </div>
                        ) : resultados[0]?.posicionSexo === "3" ? (
                          <div className="inline px-3 py-1">
                            {" "}
                            <img
                              src={tercero}
                              className="h-[20px] w-[20px] inline"
                              alt="3ro"
                              />
                            {resultados[0]?.posicionSexo}{" "}
                          </div>
                        ) : (
                          <div className="inline px-3 py-1">
                            {resultados[0]?.posicionSexo}{" "}
                          </div>
                        )}
                      </td>
                      <td class="px-2 py-4 text-sm whitespace-nowrap">
                        {resultados[0]?.posicionCategoria === "1" ? (
                          <div className="inline px-3 py-1">
                            {" "}
                            <img
                              src={primero}
                              className="h-[20px] w-[20px] inline"
                              alt="1ro"
                              />
                            {resultados[0]?.posicionCategoria}{" "}
                          </div>
                        ) : resultados[0]?.posicionCategoria === "2" ? (
                          <div className="inline px-3 py-1">
                            {" "}
                            <img
                              src={segundo}
                              className="h-[20px] w-[20px] inline"
                              alt="2do"
                              />
                            {resultados[0]?.posicionCategoria}{" "}
                          </div>
                        ) : resultados[0]?.posicionCategoria === "3" ? (
                          <div className="inline px-3 py-1">
                            {" "}
                            <img
                              src={tercero}
                              className="h-[20px] w-[20px] inline"
                              alt="3ro"
                              />
                            {resultados[0]?.posicionCategoria}{" "}
                          </div>
                        ) : (
                          <div className="inline px-3 py-1">
                            {resultados[0]?.posicionCategoria}{" "}
                          </div>
                        )}
                      </td>
                      <td class="px-2 py-4 text-sm whitespace-nowrap">
                        {resultados[0]?.categoria}
                      </td>
                      <td class="px-2 py-4 text-sm whitespace-nowrap">
                        {resultados[0]?.localidad}
                      </td>
                      <td class="px-2 py-4 text-sm whitespace-nowrap">
                        {resultados[0]?.evento.titulo}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
        )}
    </section>
  );
};

export default Resultados;
