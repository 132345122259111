import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Swal from 'sweetalert2'
import { getResDni, upgDniCorredor } from "../redux/actions";

const Perfil = ({user, corredor}) => {
    const dispatch = useDispatch();
    const [addCorredor, setAddCorredor] = useState({
        nombre: corredor.nombre,
        apellido: corredor.apellido,
        sexo: corredor.sexo,
        DNI: corredor.dni
    });


    function handleChange(e) {
        setAddCorredor({
          ...addCorredor,
          [e.target.name]: e.target.value,
        });
      }

      function handleSubmit(e) {
        e.preventDefault();
        dispatch(upgDniCorredor(user.email, addCorredor));
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500
          });
         setAddCorredor({nombre: "",
            apellido: "",
            sexo: "",
            DNI: ""}) 
      }

  return (
    <section class="py-10 my-auto bg-white">
      <div class="lg:w-[80%] md:w-[90%] xs:w-[96%] mx-auto flex gap-4">
        <div class="lg:w-[88%] md:w-[80%] sm:w-[88%] xs:w-full mx-auto shadow-2xl p-4 rounded-xl h-fit self-center bg-white">
          {/* <!--  --> */}
          <div class="">
            <h1 class="lg:text-3xl md:text-2xl sm:text-xl xs:text-xl font-serif font-extrabold mb-2 text-black">
              Perfil
            </h1>
            <form>
              <div class="flex lg:flex-row md:flex-col sm:flex-col xs:flex-col gap-2 justify-center w-full">
                <div class="w-full  mb-4 mt-6">
                  <label for="" class="mb-2 text-black">
                    Nombre
                  </label>
                  <input
                    type="text"
                    name="nombre"
                    class="mt-2 p-4 w-full border-2 text-black border-gray-400 bg-white"
                    placeholder="First Name"
                    onChange={(e) => handleChange(e)}
                    value={addCorredor.nombre}
                  />
                </div>
                <div class="w-full  mb-4 lg:mt-6">
                  <label for="" class=" text-black">
                    Apellido
                  </label>
                  <input
                    type="text"
                    name="apellido"
                    class="mt-2 p-4 w-full border-2 rounded-lg text-black border-gray-400 bg-white"
                    placeholder="Last Name"
                    onChange={(e) => handleChange(e)}
                    value={addCorredor.apellido}
                  />
                </div>
              </div>

              <div class="flex lg:flex-row md:flex-col sm:flex-col xs:flex-col gap-2 justify-center w-full">
                <div class="w-full">
                  <h3 class="text-black mb-2">Sexo</h3>
                  <select 
                  name="sexo" 
                  onChange={(e) => handleChange(e)}
                  value={addCorredor.sexo}
                  class="w-full text-grey border-2 rounded-lg p-4 pl-2 pr-2 text-black border-gray-400 bg-white">
                    <option disabled value="">
                    Selecciona sexo
                    </option>
                    <option value="Masculino">Masculino</option>
                    <option value="Femenino">Femenino</option>
                  </select>
                </div>
                <div class="w-full">
                  <h3 class="text-black mb-2">Dni</h3>
                  <input
                    type="text"
                    class="p-4 w-full border-2 rounded-lg text-black border-gray-400 bg-white"
                    name="DNI"
                    onChange={(e) => handleChange(e)}
                    value={addCorredor.DNI}
                  />
                </div>
              </div>
            </form>
              <div class="w-full rounded-lg bg-blue-500 mt-4 text-white text-lg font-semibold">
                <button onClick={handleSubmit} class="w-full p-4">
                Guardar cambios
                </button>
              </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Perfil;
