import React, { useEffect } from "react";
import { getInfoCarrera } from "../redux/actions/index";
import { useDispatch, useSelector } from "react-redux";
import CardEvento from "./CardEvento";
import { SiGooglemaps } from "react-icons/si";
import { Link } from "react-router-dom";

const Evento = () => {
  const dispatch = useDispatch();
  const props = useSelector((state) => state.carreras);
  let date = new Date
  let fechacomp = date.toISOString().split("T")[0]

  useEffect(() => {
    dispatch(getInfoCarrera());
  }, [dispatch]);

  return (
    <section class="flex flex-col items-center justify-center space-y-4 text-center text-gray-600">
      <div class="w-full pt-8 md:pt-16 lg:py-22">
        <div className="mx-auto grid max-w-5xl items-center gap-6 py-12 lg:grid-cols-2 lg:gap-12">
          {props &&
            props?.map((e) => {
              return <Link to={`/Eventos/${e.id}`}>
               <div className="relative overflow-hidden transition-transform duration-300 ease-in-out rounded-lg shadow-lg group hover:shadow-xl hover:-translate-y-2">
        <span className="sr-only">Ver carrera</span>
      <img
        src={e.imagenes}
        alt="image"
        width={550}
        height={310}
        className="object-fill w-full h-[22rem] group-hover:opacity-50 transition-opacity"
        />
      <div className="p-4 bg-background">
        <h3 className="text-xl font-bold">{e.titulo}</h3>
        <div className="flex items-center justify-center text-sm text-muted-foreground">
          <SiGooglemaps className="w-4 h-4" />
          <p className="text-sm text-muted-foreground">{e.ubicacion}</p>
        </div>
        { e.fecha < fechacomp ?
        (<a
          className="inline-flex h-6 items-center justify-center rounded-2xl bg-orange-200 px-4 py-2 text-sm font-normal shadow transition-colors hover:bg-red-400 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 ">
          Evento Finalizado
        </a>):(<a
          className="inline-flex h-6 items-center justify-center rounded-2xl bg-orange-200 px-4 py-2 text-sm font-normal shadow transition-colors hover:bg-orange-300 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 ">
          Más información
        </a>)
      }
      </div>
    </div>
                    </Link>
            })}
        </div>
      </div>  
      </section>
  );
};

export default Evento;
