import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import React from "react";
import Home from "../Componentes/Home";
import Eventos from "../Componentes/Evento.jsx";
import Ticket from "../Componentes/Ticket1.jsx";
import AboutUs from "../Componentes/AboutUs";
import { Productos } from "../Componentes/Productos";
import DetailProducto from "../Componentes/DetailProducto";
import { Profile2 } from "../Componentes/Profile2";
import MasInfoEvento from "../Componentes/MasInfoEvento.jsx";
import PostEvento from "../Componentes/PostEvento.jsx";
import Dashboard from "../Componentes/Dashboard.jsx";
import LayoutDash from "./LayoutDash.js";
import DashboardEventos from "../Componentes/DashboardEventos.jsx";
import DashboardResultados from "../Componentes/DashboardResultados.jsx";
import DashboardProductos from "../Componentes/DashboardProductos.jsx";

export const Rutas = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route exact path={"/"} element={<Home />} />
          <Route exact path={"/Nosotros"} element={<AboutUs />} />
          <Route exact path={"/Productos"} element={<Productos />} />
          <Route exact path={"/Eventos"} element={<Eventos />} />
          <Route exact path={"/Eventos/:id"} element={<MasInfoEvento />} />
          <Route exact path={"/Productos/:id"} element={<DetailProducto />} />
          <Route exact path={"/profile"} element={<Profile2 />} />
          <Route exact path={"/ticket/:id"} element={<Ticket />} />
          <Route path="/Dashboard" element={<LayoutDash />}>
            <Route
              exact
              path={"/Dashboard/Eventos"}
              element={<DashboardEventos />}
            />
            <Route
              exact
              path={"/Dashboard/Resultados"}
              element={<DashboardResultados />}
            />
            <Route
              exact
              path={"/Dashboard/Productos"}
              element={<DashboardProductos />}
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export default Rutas;
