import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createorUpdateProducto } from "../redux/actions";
import Swal from "sweetalert2";
import Cloudinary from "./Cloudinary";

const PostProducto = ({ productos }) => {
  const dispatch = useDispatch();
  const [secureURLs, setSecureURLs] = useState([]);
  const [errors, setErrors] = useState(false);
  const [img, setImg] = useState(productos.imagenes);
  const [addProducto, setAddProducto] = useState({
    id: productos.id,
    nombre: productos.nombre,
    imagenes: productos.imagenes,
    precio: productos.precio,
  });

  function validarObjeto(addProducto) {
    const errores = {};

    if (
      typeof addProducto.nombre !== "string" ||
      addProducto.nombre.length > 50
    ) {
      errores.nombre =
        "El nombre debe ser una cadena de texto de 50 caracteres o menos.";
    }

    return errores;
  }

  function handleChange(e) {
    setAddProducto({
      ...addProducto,
      [e.target.name]: e.target.value,
    });

    // Validar cada vez que cambian los datos
    setErrors(
      validarObjeto({
        ...addProducto,
        [e.target.name]: e.target.value,
      })
    );
}
const handleDeleteImg = (urlToRemove) => {
  setImg((img) => img.filter((url) => url !== urlToRemove));
};

addProducto.imagenes = img.concat(secureURLs);

  function handleSubmit(e) {
    e.preventDefault();
    const validationErrors = validarObjeto(addProducto);

    if (Object.keys(validationErrors).length === 0) {
      dispatch(createorUpdateProducto(addProducto));
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Producto modificado !",
        showConfirmButton: false,
        timer: 3000,
      }); // Si no hay errores, puedes despachar la acción para agregar el producto o cualquier otra lógica
      setAddProducto({ id: "", nombre: "", imagenes: [], precio: 0 }); // Resetea el formulario
      setErrors({});
    } else {
      setErrors(validationErrors);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Error, corrija los errores!",
        showConfirmButton: false,
        timer: 1000,
      });
    }
  }

  return (
    <div class="flex w-full justify-center my-24">
      <div className="w-[70%]">
        <h1 class="lg:text-3xl md:text-2xl sm:text-xl xs:text-xl font-serif font-extrabold mb-2 text-black">
          Nuevo producto
        </h1>
        <form onSubmit={handleSubmit}>
          <div class="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 gap-4 justify-center w-full">
            <div class="w-full  mb-4 mt-6">
              <label for="" class="mb-2 text-sm text-black">
                Titulo
              </label>
              <input
                type="text"
                name="nombre"
                class="mt-2 p-4 w-full border-2 rounded-lg text-black border-gray-400 bg-white"
                onChange={(e) => handleChange(e)}
                value={addProducto.nombre}
              />
              {errors.nombre && <p style={{ color: "red" }}>{errors.nombre}</p>}
            </div>

            <div className="w-full mb-4 lg:mt-6">
              <label htmlFor="" className="text-sm text-white">
                Imagenes
              </label>
              <div className="grid grid-cols-3">
                {img.length > 1 ? (
                  img?.map((url, i) => {
                    return (
                      <div className="relative h-[90px] m-2">
                        <button
                          type="button"
                          onClick={() => handleDeleteImg(url)}
                          className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full text-xs"
                          aria-label="Eliminar imagen"
                        >
                          &times;
                        </button>
                        <img className="h-[80px]" src={url} key={i} />
                      </div>
                    );
                  })
                ) : (
                  <div className="relative h-[90px] m-2">
                    <button
                      type="button"
                      onClick={() => handleDeleteImg(img[0])}
                      className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full text-xs"
                      aria-label="Eliminar imagen"
                    >
                      &times;
                    </button>

                    <img className="h-[80px]" src={img[0]} key={img[0]} />
                  </div>
                )}
              </div>
              <div>
                <Cloudinary
                  secureURLs={secureURLs}
                  setSecureURLs={setSecureURLs}
                />
              </div>
            </div>
            <div class="w-full mb-4 lg:mt-6">
              <label for="" class="text-sm text-black">
                Precio
              </label>
              <input
                type="number"
                name="precio"
                class="p-4 w-full border-2 rounded-lg text-black border-gray-400 bg-white"
                value={addProducto.precio}
                onChange={handleChange}
              />
              {errors.precio && <p style={{ color: "red" }}>{errors.precio}</p>}
            </div>
          </div>
          <div class="w-full rounded-lg bg-blue-500 mt-4 text-white text-lg font-semibold">
            <button type="submit" class="w-full p-4">
              Guardar cambios
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PostProducto;
