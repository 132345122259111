import React, { useState } from 'react';
import { Image } from 'cloudinary-react';

const Cloudinary = ({ secureURLs, setSecureURLs }) => {
  const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
  const uploadPreset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;

  // Maneja la carga de imágenes
  const handleImageUpload = (event) => {
    const files = event.target.files;
    const uploaders = Array.from(files).map((file) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", uploadPreset);

      return fetch(`https://api.cloudinary.com/v1_1/${cloudName}/upload`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => `https://res.cloudinary.com/${cloudName}/image/upload/${data.public_id}`);
    });

    Promise.all(uploaders)
      .then((newSecureURLs) => {
        setSecureURLs((prevSecureURLs) => [
          ...prevSecureURLs,
          ...newSecureURLs,
        ]);
      })
      .catch((error) => console.error("Error al subir imágenes:", error.toString()));
  };

  // Maneja la eliminación de una imagen
  const handleImageRemove = (urlToRemove) => {
    setSecureURLs((prevSecureURLs) =>
      prevSecureURLs.filter((url) => url !== urlToRemove)
    );
  };

  return (
    <div>
      {/* Input para cargar imágenes */}
      <input type="file" multiple onChange={handleImageUpload} />
      
      {/* Muestra las imágenes cargadas */}
      <div className="flex flex-row flex-wrap mt-4">
        {secureURLs.map((url, index) => (
          <div key={index} className='relative w-[90px] h-[90px] m-2'>
            <Image
              cloudName={cloudName}
              publicId={url}
              crop="scale"
              width="100%"
              height="100%"
              style={{ objectFit: 'cover' }}
            />
            <button
              type="button"
              onClick={() => handleImageRemove(url)}
              className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full text-xs"
              aria-label="Eliminar imagen"
            >
              &times;
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Cloudinary;
