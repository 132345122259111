import React from "react";
import { Link } from "react-router-dom";

const CartaProducto = ({ producto }) => {
  return (
    <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
      <Link to={`/Productos/${producto.id}`}>
        <a class="block relative h-48 rounded overflow-hidden">
          <img
            alt="ecommerce"
            class="object-contain object-center w-full h-full block"
            src={producto.imagenes[0]}
          />
        </a>
        <div class="mt-4 text-center">
          <h2 class="text-gray-900 title-font text-lg font-medium">
            {producto.nombre}
          </h2>
          <p class="mt-1">${producto.precio}</p>
        </div>
      </Link>
    </div>
  );
};

export default CartaProducto;
