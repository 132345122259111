import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cargaResultados, getInfoCarrera } from "../redux/actions";
import Papa from "papaparse";
import Swal from "sweetalert2";

const DashboardResultados = () => {
  const dispatch = useDispatch();
  const eventos = useSelector((state) => state.carreras);
  const [resultados, setResultados] = useState([]);
  const [fileError, setFileError] = useState("");
  const [eventoId, setEventoId] = useState("");
  // Cargar los eventos cuando el componente se monte
  useEffect(() => {
    dispatch(getInfoCarrera());
  }, [dispatch]);

  // Maneja el envío de los resultados
  const enviarDatos = () => {
    if (resultados.length === 0 || eventoId === "") {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Error, Verifique que cargó resultados y que seleccionó un evento",
        showConfirmButton: false,
        timer: 4000,
      });
      return;
    } else {
      dispatch(cargaResultados(eventoId, resultados));
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Resultados agregados",
        showConfirmButton: false,
        timer: 3000,
      });
      window.location.reload();
    }
  };
  // Procesar archivo JSON o CSV cargado
  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      const extension = file.name.split(".").pop().toLowerCase();

      reader.onload = (e) => {
        const fileContent = e.target.result;

        if (extension === "json") {
          try {
            const jsonResults = JSON.parse(fileContent);
            setResultados(jsonResults);
            setFileError("");
          } catch (err) {
            setFileError("Error al procesar el archivo JSON");
          }
        } else if (extension === "csv") {
          Papa.parse(fileContent, {
            header: true,
            complete: (result) => {
              setResultados(result.data);
              setFileError("");
            },
            error: () => setFileError("Error al procesar el archivo CSV"),
          });
        } else {
          setFileError("Formato de archivo no soportado. Solo JSON o CSV.");
        }
      };

      reader.readAsText(file);
    }
  };

  // Agregar un resultado manualmente
  const handleManualAdd = () => {
    setResultados([
      ...resultados,
      {
        posicionGeneral: "",
        posicionSexo: "",
        posicionCategoria: "",
        dorsal: "",
        nombreApellido: "",
        categoria: "",
        localidad: "",
        total: "",
        diferenciaPrimero: "",
        diferenciaAnterior: "",
        DNI: "",
      },
    ]);
  };

  // Actualizar campo individual de un resultado
  const handleInputChange = (index, field, value) => {
    const updatedResultados = [...resultados];
    updatedResultados[index][field] = value;
    setResultados(updatedResultados);
  };

  return (
    <div className="flex flex-col items-center mt-8">
      <h1 className="text-3xl font-bold mb-6">Subir resultados</h1>
      <>
        {/* Selección de Evento */}
        <label htmlFor="evento" className="block text-lg mb-2">
          Selecciona el evento al que está relacionado el resultado:
        </label>
        <select
          id="evento"
          onChange={(e) => setEventoId(e.target.value)}
          className="block w-64 p-2 border border-gray-300 rounded mb-4"
        >
          <option value="">Selecciona un evento</option>
          {eventos.map((evento) => (
            <option key={evento.id} value={evento.id}>
              {evento.titulo}
            </option>
          ))}
        </select>

        {/* Subir archivo JSON o CSV */}
        <div className="mb-4">
          <label htmlFor="file" className="block text-lg mb-2">
            Cargar archivo (JSON o CSV):
          </label>
          <input
            type="file"
            accept=".json,.csv"
            onChange={handleFileUpload}
            className="block w-full p-2 border border-gray-300 rounded"
          />
          {fileError && <p className="text-red-500">{fileError}</p>}
        </div>
        {/* Botón de Enviar */}
        <button
          onClick={enviarDatos}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          disabled={resultados.length === 0}
        >
          Enviar resultados
        </button>
        {/* Ingresar resultados manualmente */}
        <div className="mb-4">
          <button
            onClick={handleManualAdd}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          >
            Agregar Resultado Manualmente
          </button>
        </div>

        {/* Mostrar resultados ingresados o cargados */}
        {resultados.length > 0 && (
          <div className="w-full max-w-4xl mb-6">
            <h2 className="text-xl font-bold mb-4">Resultados ingresados:</h2>
            {resultados.map((resultado, index) => (
              <div key={index} className="mb-2">
                <input
                  type="text"
                  placeholder="Posición General"
                  value={resultado.posicionGeneral}
                  onChange={(e) =>
                    handleInputChange(index, "posicionGeneral", e.target.value)
                  }
                  className="border p-2"
                />
                <input
                  type="text"
                  placeholder="Posición Sexo"
                  value={resultado.posicionSexo}
                  onChange={(e) =>
                    handleInputChange(index, "posicionSexo", e.target.value)
                  }
                  className="border p-2"
                />
                <input
                  type="text"
                  placeholder="Posición Categoría"
                  value={resultado.posicionCategoria}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "posicionCategoria",
                      e.target.value
                    )
                  }
                  className="border p-2"
                />
                <input
                  type="text"
                  placeholder="Dorsal"
                  value={resultado.dorsal}
                  onChange={(e) =>
                    handleInputChange(index, "dorsal", e.target.value)
                  }
                  className="border p-2"
                />
                <input
                  type="text"
                  placeholder="Nombre y Apellido"
                  value={resultado.nombreApellido}
                  onChange={(e) =>
                    handleInputChange(index, "nombreApellido", e.target.value)
                  }
                  className="border p-2"
                />
                <input
                  type="text"
                  placeholder="DNI"
                  value={resultado.DNI}
                  onChange={(e) =>
                    handleInputChange(index, "DNI", e.target.value)
                  }
                  className="border p-2"
                />
                <input
                  type="text"
                  placeholder="Categoría"
                  value={resultado.categoria}
                  onChange={(e) =>
                    handleInputChange(index, "categoria", e.target.value)
                  }
                  className="border p-2"
                />
                <input
                  type="text"
                  placeholder="Localidad"
                  value={resultado.localidad}
                  onChange={(e) =>
                    handleInputChange(index, "localidad", e.target.value)
                  }
                  className="border p-2"
                />
                <input
                  type="text"
                  placeholder="Total"
                  value={resultado.total}
                  onChange={(e) =>
                    handleInputChange(index, "total", e.target.value)
                  }
                  className="border p-2"
                />
                <input
                  type="text"
                  placeholder="Diferencia con el Primero"
                  value={resultado.diferenciaPrimero}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "diferenciaPrimero",
                      e.target.value
                    )
                  }
                  className="border p-2"
                />
                <input
                  type="text"
                  placeholder="Diferencia con el Anterior"
                  value={resultado.diferenciaAnterior}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "diferenciaAnterior",
                      e.target.value
                    )
                  }
                  className="border p-2"
                />
              </div>
            ))}
          </div>
        )}
      </>
    </div>
  );
};
export default DashboardResultados;
